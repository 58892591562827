import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_bg_original4.jpg';


const About = () => {
    const {t, i18n} = useTranslation(['about', 'common', 'title'])
    const isChinese = i18n.language === 'zh';
    return (
        <div className={"bg-white"}>
            <SEO title={t('about', {ns: 'html_title'})}></SEO>
            <div className={"bg-cover bg-white bg-blend-hard-light bg-bottom bg-no-repeat min-h-[350px] sm:min-h-[400px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start max-w-[900px] mt-36 mb-16 md:mr-48"}>
                    <div className={` font-bold tracking-[.1em] text-white/90 leading-normal ${isChinese ? 'text-4xl sm:text-[3.5rem] sm:tracking-[.05em]' : 'text-4xl sm:text-[3rem] sm:tracking-[.03em]'}`}>
                        {isChinese ? '人工智能伦理与安全评测体系' : 'AI Ethics and Safety Assessment System'}
                    </div>
                </div>
            </div>
            <div className={"content-container py-24 px-5"}>
                <div className={"flex flex-col justify-center items-center max-w-6xl mx-auto px-6 sm:px-12 pb-36 rounded-3xl bg-cover bg-white bg-blend-multiply bg-center-top bg-no-repeat"}>
                    <div className={""}>
                        {/*<p className="text-neutral-600 text-xl font-light font-sans leading-loose">{t('p1')}</p>*/}
                        {isChinese ?
                            <div className="text-neutral-600 text-xl font-light font-sans leading-loose">
                                <div className="mt-3 text-[#C00000] font-bold">基础模型：</div>
                                <ul className="list-disc">
                                    <li>人工智能伦理与安全评测体系</li>
                                </ul>

                                <div className="mt-3 text-[#C00000] font-bold">关键技术领域：</div>
                                <ul className="list-disc">
                                    <li>生成式人工智能伦理与安全评测体系</li>
                                    <li>生物特征识别伦理与安全评测体系</li>
                                    <li>算法推荐伦理与安全评测体系</li>
                                    <li>数字人伦理与安全评测体系</li>
                                    <li>自动驾驶伦理与安全评测体系</li>
                                    <li>人形机器人伦理与安全评测体系</li>
                                    <li>……</li>
                                </ul>

                                <div className="mt-3 text-[#C00000] font-bold">行业应用领域：</div>
                                <ul className="list-disc">
                                    <li>教育领域人工智能伦理与安全评测体系</li>
                                    <li>卫生健康领域人工智能伦理与安全评测体系</li>
                                    <li>……</li>
                                </ul>
                            </div>
                            :
                            <div className="text-neutral-600 text-xl font-light font-sans leading-loose">
                                <div className="mt-3 text-[#C00000] font-bold">Basic Model:</div>
                                <ul className="list-disc">
                                    <li>AI Ethics and Safety Assessment System</li>
                                </ul>

                                <div className="mt-3 text-[#C00000] font-bold">Key Technical Areas:</div>
                                <ul className="list-disc">
                                    <li>Generative AI Ethics and Safety Assessment System</li>
                                    <li>Biometric Recognition Ethics and Safety Assessment System</li>
                                    <li>Algorithm Recommendation Ethics and Safety Assessment System</li>
                                    <li>Digital Human Ethics and Safety Assessment System</li>
                                    <li>Autonomous Driving Ethics and Safety Assessment System</li>
                                    <li>Humanoid Robot Ethics and Safety Assessment System</li>
                                    <li>……</li>
                                </ul>

                                <div className="mt-3 text-[#C00000] font-bold">Industry Application Areas:</div>
                                <ul className="list-disc">
                                    <li>AI Ethics and Safety Assessment System in the Education Sector</li>
                                    <li>AI Ethics and Safety Assessment System in the Healthcare Sector</li>
                                    <li>……</li>
                                </ul>
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;