import React, {useEffect, useRef, useState} from 'react';
import {useTranslation} from "react-i18next";
import SEO from "./SEO";
import BrainCog_Born from "./BrainCog_Born";
import heroBackgroundImage from "../assets/images/hero_background/about_hero_bg_original4.jpg";

function Services() {
    const {t, i18n} = useTranslation(['home', 'html_title', 'common']);
    const isChinese = i18n.language === 'zh';

    return (
        <div className="">
            <SEO title={t('services', {ns: 'html_title'})}></SEO>
            <div className={"bg-cover bg-white bg-blend-hard-light bg-bottom bg-no-repeat min-h-[350px] sm:min-h-[400px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-center max-w-[900px] mt-36 mb-16"}>
                    <div className={`text-center font-bold tracking-[.05em] text-white leading-normal ${isChinese ? 'text-4xl sm:text-[3.5rem] sm:tracking-[.05em]' : 'text-4xl sm:text-[3rem] sm:tracking-[.03em]'}`}>
                        {t('project_name_full', {ns: 'common'})}<br />
                        {isChinese ? '公共服务平台' : 'AI Safety and Governance Platforms'}
                    </div>
                </div>
            </div>
            <div className={"content-container pt-12 pb-48"}>
                <BrainCog_Born brainknow={true}/>
            </div>
        </div>
    )
}

export default Services