import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_bg_original4.jpg';


const Examiner = () => {
    const {t, i18n} = useTranslation(['about', 'common', 'title'])
    const isChinese = i18n.language === 'zh';
    return (
        <div className={"bg-white"}>
            <SEO title={t('about', {ns: 'html_title'})}></SEO>
            <div className={"bg-cover bg-white bg-blend-hard-light bg-bottom bg-no-repeat min-h-[350px] sm:min-h-[400px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start max-w-[900px] mt-36 mb-16 md:mr-48"}>
                    <div className={` font-bold tracking-[.1em] text-white/90 leading-normal ${isChinese ? 'text-4xl sm:text-[3.5rem] sm:tracking-[.05em]' : 'text-4xl sm:text-[3rem] sm:tracking-[.03em]'}`}>
                        {isChinese ? '人工智能大模型安全伦理主考官' : 'Large AI Models Safety and Ethics Examiner'}
                    </div>
                </div>
            </div>
            <div className={"content-container py-24 px-5"}>
                <div className={"flex flex-col justify-center items-center max-w-6xl mx-auto px-6 sm:px-12 pb-36 rounded-3xl bg-cover bg-white bg-blend-multiply bg-center-top bg-no-repeat"}>
                    <div className={""}>
                        {/*<p className="text-neutral-600 text-xl font-light font-sans leading-loose">{t('p1')}</p>*/}
                        {isChinese ?
                            <div className="text-neutral-600 text-xl font-light font-sans leading-loose">
                                <ol className="list-decimal">
                                    <li>中科院自动化所：智善如流人工智能伦理道德与社会规范群智平台</li>
                                    <li>中国信通院大模型安全基准测试</li>
                                    <li>清华大学多模态大模型安全评测</li>
                                </ol>
                            </div>
                            :
                            <div className="text-neutral-600 text-xl font-light font-sans leading-loose">
                                <ul className="list-decimal">
                                    <li>Institute of Automation, Chinese Academy of Sciences：Social data and knowledge collective intelligence platform for TRaining Ethical AI Models</li>
                                    <li>China Academy of Information and Communications Technology (CAICT) Large Model Safety Benchmark</li>
                                    <li>Tsinghua University Multimodal Large Language Model Safety Assessment</li>
                                </ul>
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Examiner;