import {useParams, useLocation, Link} from "react-router-dom";
import '../styles/Common.css';
import '../styles/About.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_bg_original4.jpg';
import React from "react";


const Paper = () => {
    const {t, i18n} = useTranslation(['about', 'common', 'title', 'home'])
    const isChinese = i18n.language === 'zh';

    const {slug} = useParams(); // Get the slug from the URL
    console.log("slug:", slug);

    /*  const location = useLocation();
      const slug = location.pathname.split('/').pop(); // Extract the last part of the pathname
      console.log("slug:", slug);*/

    // Fetch all news articles
    const articles = t('news', {ns: 'articles', returnObjects: true});
    console.log("articles:", articles);
    // Find the article that matches the slug
    let article = articles.find(item => item.teaser_url === `/articles/${slug}`);
    // const article = articles[0];
    console.log("article:", article);
    if (!article) {
        article = articles[0];
        console.log("warning: article not found, using first article instead");
    }

    if (!article) {
        return <div>Article not found</div>;
    }

    return (
        <div className={"bg-white overflow: auto"}>
            <SEO title={"StressPrompt: Does Stress Impact Large Language Models and Human Performance Similarly?"}></SEO>
            <div className={"bg-cover bg-white bg-blend-hard-light bg-bottom bg-no-repeat min-h-[350px] sm:min-h-[400px] w-full flex flex-col justify-start items-center p-6"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className={"flex flex-col justify-center items-start max-w-[900px] mt-36 mb-16 md:mr-48"}>
                    <div className={` font-bold tracking-[.1em] text-white/90 leading-normal ${isChinese ? 'text-4xl sm:text-[3.5rem] sm:tracking-[.02em]' : 'text-4xl sm:text-[3rem] sm:tracking-[.02em]'}`}>
                        StressPrompt: Does Stress Impact Large Language Models and Human Performance Similarly?
                    </div>
                </div>
            </div>
            <div className={"content-container py-12"}>
                <div className={"flex flex-wrap justify-evenly items-start max-w-8xl mx-auto px-6 sm:px-12 pb-36 rounded-3xl bg-cover bg-white bg-blend-multiply bg-center-top bg-no-repeat"}>
                    <div className={"lg:max-w-[60%] min-w-0"}>
                        <div className={"text-center flex flex-col justify-center items-center mt-3 mb-6 text-neutral-800 text-normal font-light font-sans "}>
                            <div className="is-size-5 publication-authors">
                                <span className="author-block">
                                  <a href="https://scholar.google.com/citations?user=Sv-WdBkAAAAJ&amp;hl">Guobin Shen</a><sup>1,2,3,4*</sup>,</span>
                                <span className="author-block">
                                  <a href="https://scholar.google.com/citations?user=2E9Drq8AAAAJ&amp;hl">Dongcheng Zhao</a><sup>1,2,3*</sup>,</span>
                                <span className="author-block">
                                  <a href="ttps://scholar.google.co.uk/citations?user=296nWDwAAAAJ&amp;hl=en&amp;oi=ao">Aorigele Bao</a><sup>1,2,3,5</sup>,
                                </span>
                                <span className="author-block">
                                  <a href="https://scholar.google.com/citations?user=Em5FqXYAAAAJ&amp;hl=zh-CN">Xiang He</a><sup>1,2,3</sup>,
                                </span>
                                <span className="author-block">
                                  <a href="https://scholar.google.com/citations?user=YLbBO9IAAAAJ&amp;hl">Yiting Dong</a><sup>1,2,3,4</sup>,
                                </span>
                                <span className="author-block">
                                  <a href="https://scholar.google.com/citations?user=Rl-YqPEAAAAJ&amp;hl">Yi Zeng</a><sup>1,2,3,5</sup>,
                                </span>
                            </div>
                            <div className="flex flex-col justify-center items-center mt-3 mb-6">
                                <span className="author-block"><sup>1</sup>Brain-inspired Cognitive Intelligence Lab, Institute of Automation, Chinese Academy of Sciences</span>
                                <span className="author-block"><sup>2</sup>Beijing Institute of AI Safety and Governance</span>
                                <span className="author-block"><sup>3</sup>Center for Long-term Artificial Intelligence</span>
                                <span className="author-block"><sup>4</sup>School of Future Technology, University of Chinese Academy of Sciences</span>
                                <span className="author-block"><sup>5</sup>Department of Philosophy, School of Humanities, University of Chinese Academy of Sciences</span>
                            </div>
                        </div>
                        <p className={"text-neutral-600 text-normal font-light font-sans mb-4 text-center"}>24 Aug 2024</p>
                        <div className={"flex flex-wrap justify-between align-start"}>
                            <div className="mt-10 mb-8 md:max-w-[45%]">
                                <img src={process.env.PUBLIC_URL + `/images/articles/StressPrompt/1.png`}
                                     className="mb-4 w-full h-auto rounded-lg object-contain"/>
                                <div className={"text-neutral-600 text-sm font-light font-sans text-center"}>Performance of Llama-3-8B-Instruct on Leaderboard 2 Benchmark under different stress levels.</div>
                            </div>
                            <div className="mt-10 mb-8 md:max-w-[45%]">
                                <img src={process.env.PUBLIC_URL + `/images/articles/StressPrompt/2.png`}
                                     className="mb-4 w-full h-auto rounded-lg object-contain"/>
                                <div className={"text-neutral-600 text-sm font-light font-sans text-center"}>
                                    <a href="https://en.wikipedia.org/wiki/Yerkes%E2%80%93Dodson_law">Yerkes–Dodson law</a>
                                </div>
                            </div>
                        </div>

                        <div className="mt-10 mb-4 text-2xl text-neutral-800 font-bold font-sans">
                            Abstract
                        </div>
                        <p className={"text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            Human beings often experience stress, which can significantly influence their performance. This study explores whether Large Language Models (LLMs) exhibit stress responses similar to those of humans and whether their performance fluctuates under different stress-inducing
                            prompts. To investigate this, we developed a novel set of prompts, termed StressPrompt, designed to induce varying levels of stress. These prompts were derived from established psychological frameworks and carefully calibrated based on ratings from human participants. We
                            then applied these prompts to several LLMs to assess their responses across a range of tasks, including instruction-following, complex reasoning, and emotional intelligence. The findings suggest that LLMs, like humans, perform optimally under moderate stress, consistent
                            with the Yerkes-Dodson law. Notably, their performance declines under both low and high-stress conditions. Our analysis further revealed that these StressPrompts significantly alter the internal states of LLMs, leading to changes in their neural representations that
                            mirror human responses to stress. This research provides critical insights into the operational robustness and flexibility of LLMs, demonstrating the importance of designing AI systems capable of maintaining high performance in real-world scenarios where stress is
                            prevalent, such as in customer service, healthcare, and emergency response contexts. Moreover, this study contributes to the broader AI research community by offering a new perspective on how LLMs handle different scenarios and their similarities to human cognition.
                        </p>

                        <div className="mt-10 mb-4 text-2xl text-neutral-800 font-bold font-sans">
                            Prompt Design
                        </div>
                        <p className={"text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            To systematically investigate the impact of stress on LLM performance, we developed a dataset named StressPrompt, grounded in established psychological theories. The objective was to design prompts that elicit varying levels of stress, thereby enabling the evaluation of
                            LLMs under different stress conditions.As illustrated in Figure, the prompts were developed based on four key psychological frameworks, each offering a distinct perspective on stress and cognitive performance:
                        </p>
                        <div className="mt-4 mb-2 text-lg text-neutral-800 font-normal font-sans">
                            Stress and Coping Theory
                        </div>
                        <p className={"text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            This theory focuses on how individuals appraise and cope with stressors. We developed prompts to simulate varying levels of perceived threat and challenge, as well as the coping strategies employed, to provide insight into the dynamic interaction between stress appraisal
                            and cognitive functioning.
                        </p>
                        <div className="mt-4 mb-2 text-lg text-neutral-800 font-normal font-sans">
                            Job Demand-Control Model
                        </div>
                        <p className={"text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            This model suggests that job stress is influenced by the balance between job demands and the control or autonomy an individual has over their work tasks. We designed prompts to simulate scenarios with varying job demands and levels of control, allowing us to study their
                            effects on stress and cognitive performance.
                        </p>
                        <div className="mt-4 mb-2 text-lg text-neutral-800 font-normal font-sans">
                            Conservation of Resources Theory
                        </div>
                        <p className={"text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            This theory posits that stress occurs when there is a threat to, loss of, or insufficient gain of resources necessary to achieve one's goals. Using this framework, we created prompts that explore the dynamics of resource gain, loss, and protection in the context of
                            stress, highlighting how these factors influence cognitive performance.
                        </p>
                        <div className="mt-4 mb-2 text-lg text-neutral-800 font-normal font-sans">
                            Effort-Reward Imbalance Model
                        </div>
                        <p className={"text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            According to this model, stress arises from an imbalance between the efforts an individual puts into their work and the rewards they receive. We crafted prompts to examine scenarios where this balance is either maintained or disrupted, assessing its impact on stress
                            levels and task performance.
                        </p>
                        <div className={"flex flex-wrap justify-between align-start"}>
                            <div className="mt-10 mb-8 md:max-w-[45%]">
                                <img src={process.env.PUBLIC_URL + `/images/articles/StressPrompt/3.png`}
                                     className="mb-4 w-full h-auto rounded-lg object-contain"/>
                                <div className={"text-neutral-600 text-sm font-light font-sans text-center"}>Design of StressPrompt based on psychological principles. Each category encompasses a range of stress-inducing scenarios, ensuring a comprehensive set of prompts for our study.</div>
                            </div>
                            <div className="mt-10 mb-8 md:max-w-[45%]">
                                <img src={process.env.PUBLIC_URL + `/images/articles/StressPrompt/4.png`}
                                     className="mb-4 w-full h-auto rounded-lg object-contain"/>
                                <div className={"text-neutral-600 text-sm font-light font-sans text-center"}>StressPrompt acts as a system instruction, simulating different environments and influencing the LLM's response. Left: Low stress level. Right: Moderately high stress level.</div>
                            </div>
                        </div>

                        <div className="mt-10 mb-3 text-2xl text-neutral-800 font-bold font-sans">
                            Stress Scanner
                        </div>
                        <p className={"text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            To further investigate how stress impacts the internal states of LLMs, we developed a Stress Scanner using techniques inspired by Representation Engineering (RepE). The Stress Scanner examines how different stress prompts from the StressPrompt dataset affect the hidden
                            states of LLMs across various layers and token positions.
                        </p>
                        <p className={"mt-2 text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            We visualized the distribution of stress scores across different layers and token positions to identify patterns of neural activity under varying stress conditions. By systematically analyzing the stress-induced changes in neural activity, we gain a deeper understanding
                            of the effects of stress on LLMs and their alignment with human stress responses. This approach offers a novel method for evaluating the robustness and resilience of LLMs under varying stress conditions.
                        </p>
                        <div className={"flex flex-wrap justify-between align-start"}>
                            <div className="mt-10 mb-8 md:max-w-[45%]">
                                <img src={process.env.PUBLIC_URL + `/images/articles/StressPrompt/5.png`}
                                     className="mb-4 w-full h-auto rounded-lg object-contain"/>
                                <div className={"text-neutral-600 text-sm font-light font-sans text-center"}>Stress scanner constructed with RepE. Various StressPrompt induce differences in the neural activity of LLMs, with the last token showing the most significant correlation with stress.</div>
                            </div>
                            <div className="mt-10 mb-8 md:max-w-[45%]">
                                <img src={process.env.PUBLIC_URL + `/images/articles/StressPrompt/6.png`}
                                     className="mb-4 w-full h-auto rounded-lg object-contain"/>
                                <div className={"text-neutral-600 text-sm font-light font-sans text-center"}>Heatmap of neural activity of the last token across all layers for various stress levels in Llama-3-8B-Instruct.</div>
                            </div>
                        </div>

                        <div className="mt-10 mb-3 text-2xl text-neutral-800 font-bold font-sans">
                            Experiments
                        </div>
                        <p className={"text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            The experimental results summarized illustrate the effects of varying stress levels induced by StressPrompts on the performance of different language models across multiple tasks. Our analysis focuses on the impact of stress on several dimensions, including task
                            performance, model sensitivity, and general trends observed.
                        </p>
                        <p className={"mt-2 text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            In most tasks, moderate stress levels enhance performance, while high stress levels lead to declines, consistent with the Yerkes-Dodson law. This suggests that moderate stress stimulates cognitive engagement, whereas excessive stress overwhelms the system and impairs
                            function.
                        </p>
                        <p className={"mt-2 text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            A comparative analysis across different benchmarks shows the varied effects of stress on multiple dimensions of LLM capabilities. Our study makes several key contributions:
                        </p>
                        <p className={"mt-2 text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            For emotional intelligence, models exhibit improved performance under moderate stress, with declines at both low and high stress extremes. This suggests that a balanced level of arousal enhances cognitive engagement without overwhelming the model.
                        </p>
                        <p className={"mt-2 text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            In contrast, increased stress levels correlate with declining performance in bias detection, indicating that higher stress exacerbates biases. This finding is critical for applications requiring unbiased decision-making, such as content moderation.
                            Regarding hallucination susceptibility, stress has minimal impact, with performance remaining stable across stress levels. This suggests that hallucinations are driven more by intrinsic model factors rather than by stress-induced arousal.
                        </p>
                        <p className={"mt-2 text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            These findings underscore the importance of tailoring stress levels to optimize LLM performance, particularly in tasks demanding high emotional intelligence and fairness. By understanding how stress affects different cognitive and social competencies, we can better align
                            LLMs with human-like responses, enhancing their utility in diverse applications.
                        </p>
                        <div className={"flex flex-wrap justify-between align-start"}>
                            <div className="mt-10 mb-8 md:max-w-[45%]">
                                <img src={process.env.PUBLIC_URL + `/images/articles/StressPrompt/7.png`}
                                     className="mb-4 w-full h-auto rounded-lg object-contain"/>
                                <div className={"text-neutral-600 text-sm font-light font-sans text-center"}>
                                    Performance comparison of Llama-3-8B-Instruct and Phi-3-mini-4k-instruct across different stress levels on various benchmarks.
                                </div>
                            </div>
                            <div className="mt-10 mb-8 md:max-w-[45%]">
                                <img src={process.env.PUBLIC_URL + `/images/articles/StressPrompt/8.png`}
                                     className="mb-4 w-full h-auto rounded-lg object-contain"/>
                                <div className={"text-neutral-600 text-sm font-light font-sans text-center"}>
                                    Performance changes compared to baseline across different stress levels for EQ-Bench, ToxiGen, and TruthfulQA.
                                </div>
                            </div>
                        </div>

                        <div className="mt-10 mb-3 text-2xl text-neutral-800 font-bold font-sans">
                            Conclusion
                        </div>
                        <p className={"text-neutral-600 text-xl font-light font-sans leading-normal"}>
                            In this study, we constructed a dataset named StressPrompt to induce varying levels of stress in LLMs. Our analysis shows that stress significantly affects the internal states of LLMs, with deeper layers exhibiting higher sensitivity to stress levels. Moderate stress can
                            enhance performance in tasks such as instruction following, reasoning, and emotional intelligence, while higher stress levels negatively impact bias detection. We developed a stress scanner that effectively measures the impact of stress on LLMs' internal states, providing
                            a tool to evaluate model robustness and resilience. These findings highlight the necessity of adjusting stress levels based on task requirements to optimize LLM performance. Identifying optimal stress levels can improve the resilience and adaptability of AI systems,
                            ensuring reliable performance under pressure. Future research could explore other psychological phenomena and their effects on LLMs, further bridging the gap between human intelligence and artificial intelligence.
                        </p>

                        <a href={process.env.PUBLIC_URL + `/publication/StressPrompt.pdf`} className={"block mt-8 sm:mt-8 lg:mt-8 text-neutral-700 text-lg font-light uppercase"}>
                            <span className="underline decoration-red-400 hover:decoration-red-600 underline-offset-8 decoration-2 transition-colors duration-500">Download Full Paper</span>
                        </a>

                        <div className="mt-10 mb-3 text-2xl text-neutral-800 font-bold font-sans">
                            Reference BibTeX
                        </div>
                        <pre className="max-w-full overflow-x-auto"><code className={"text-neutral-600 text-xl font-light font-sans leading-normal"}>
                        {`@misc{shen2024stressprompt,
    author    = {Guobin Shen and Dongcheng Zhao and Aorigele Bao and Xiang He and Yiting Dong and Yi Zeng},
    title     = {StressPrompt: Does Stress Impact Large Language Models and Human Performance Similarly?},
    year      = {2024},
}`}
                        </code></pre>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Paper;
