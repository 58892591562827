import React, {useEffect, useRef, useState} from 'react';
import { Link } from 'react-router-dom';
// import loading from '../assets/images/loading.svg'
import styles from '../styles/Home.module.css';
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import {Dialog} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import SEO from "./SEO";

import HomeNewsEntrance from './HomeNewsEntrance';
import BrainCog_Born from './BrainCog_Born';
import Datasets from './Datasets';
import {Fade} from "react-awesome-reveal";
import heroBackgroundImage from '../assets/images/hero_background/about_hero_bg_original4.jpg';

function Home() {
    const {t, i18n} = useTranslation(['home', 'articles', 'html_title', 'common']);
    const isChinese = i18n.language === 'zh';
    const newsList = [t(`news`, {ns: 'articles', returnObjects: true})[0]];console.log(newsList);


    const services = [
        {name: "mind_talk", href: 'http://mind-engine.org/mind-talk/', intro: t("service_intro_mind_talk")},
        {name: "mind_mate", href: 'http://mind-engine.org/mind-mate/', intro: t("service_intro_mind_mate")},
        {name: "symbiosis_panorama", href: 'http://symbiosis-panorama.ai-for-sdgs.academy/cn/', intro: t("service_intro_mind_talk")},
        {name: "cie", href: 'http://cultural-interactions-engine.ai-for-sdgs.academy/', intro: t("service_intro_mind_talk")},
        {name: "digital_philosophers", href: 'http://cn.digital-philosophers.org/', intro: t("service_intro_mind_talk")},
        {name: "sea", href: 'https://safe-and-ethical.ai/', intro: t("service_intro_mind_talk")},
    ];

    const organizations_cn = [
        {name: t("organization_name_1"), href: 'javascript:void(0)', intro: t("organization_intro_1")},
        {name: t("organization_name_2"), href: 'javascript:void(0)', intro: t("organization_intro_2")},
        {name: t("organization_name_3"), href: 'javascript:void(0)', intro: t("organization_intro_3")},
        {name: t("organization_name_4"), href: 'https://long-term-ai.center/', intro: t("organization_intro_4")},
    ];

    const organizations_en = [
        {name: t("organization_name_4"), href: 'https://long-term-ai.center/', intro: t("organization_intro_4")},
        {name: t("organization_name_1"), href: 'javascript:void(0)', intro: t("organization_intro_1")},
        {name: t("organization_name_2"), href: 'javascript:void(0)', intro: t("organization_intro_2")},
        {name: t("organization_name_3"), href: 'javascript:void(0)', intro: t("organization_intro_3")},
    ];

    return (
        <div className="bg-white pb-64">
            <SEO title={t('home', {ns: 'html_title'})}></SEO>
            {/*hero*/}
            <div
                className={`relative isolate px-6 pt-14 lg:px-8 h-screen bg-animation ${styles.bg_animation} flex items-center justify-center`}
                style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                <div className="absolute inset-0 -z-1"></div>
                <div className="mx-auto max-w-7xl py-32 sm:py-48 lg:py-48 z-0">
                    {/*                    <div className="hidden sm:mb-16 sm:flex sm:justify-center">
                        <div
                            className="relative rounded-full px-6 py-1 text-sm leading-6 text-gray-300 ring-1 ring-gray-100/50 hover:ring-gray-100/40"
                        >
                            {t("announcing")}.{'  '}
                            <a href="#services" className="font-semibold text-indigo-400">
                                <span className="absolute inset-0" aria-hidden="true"/>
                                {t("get_started")} <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </div>*/}
                    <div className="text-center">
                        <div
                            className={`text-4xl font-bold tracking-[.1em] bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-neutral-50/100 to-neutral-300/80 ${isChinese ? ' sm:text-[3.5rem] lg:text-[4.5rem] leading-tight sm:tracking-[.10em]' : 'sm:text-[3.5rem] leading-none sm:leading-tight sm:tracking-[.01em]'}`}>
                            {t('hero_heading_1')}
                        </div>
                        <p className={`mt-12 text-lg font-light font-sans text-neutral-300 sm:text-neutral-300 sm:text-xl leading-5 sm:leading-8 ${isChinese ? 'tracking-[.05em] sm:tracking-[.01em]' : 'leading-5 tracking-[.01em] sm:tracking-[.01em]'}`}>
                            {t('hero_heading_2')}
                        </p>
                        <div className="mt-5 sm:mt-[7rem] flex items-center justify-center gap-x-6">
                            {/*                            <a
                                href="#engines"
                                className="rounded-3xl bg-transparent border-2 border-neutral-400 px-9 pt-2.5 pb-2 text-sm font-normal text-neutral-300 shadow-sm hover:bg-neutral-200/10 duration-150 tracking-[.2em] uppercase"
                            >
                                {t("get_started")}
                            </a>*/}
                            <a href="#logo_animation" id="action_button" className="relative inline-flex h-12 overflow-hidden rounded-full p-[2px] focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 focus:ring-offset-slate-50">
                                <span className="absolute inset-[-1000%] animate-[spin_2s_linear_infinite] bg-[conic-gradient(from_90deg_at_50%_50%,#E2CBFF_0%,#c00000_50%,#E2CBFF_100%)]"></span>
                                <span className="inline-flex h-full w-full cursor-pointer items-center justify-center rounded-full bg-black/80 px-9 pt-2.5 pb-2 backdrop-blur-3xl text-sm font-normal text-neutral-300 shadow-sm hover:bg-red-950 duration-150 tracking-[.2em] uppercase">
                                {t("get_started")}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div id={"logo_animation"} className={"flex justify-center items-center flex-col pt-12 px-5 bg-white"}>
                <video
                    src={process.env.PUBLIC_URL + `/logo/red_logo_min.mp4`}
                    className={"max-w-[400px] bg-white rounded-full p-[2px]"}
                    loop
                    muted
                    autoPlay
                />
            </div>
            <div id={"news"} className={"flex justify-center items-center flex-col pt-16 pb-16 px-5 bg-white"}>
                {newsList && newsList.map((item, i) => (
                    <HomeNewsEntrance key={i} teaser_title={item.teaser_title} teaser_content={item.teaser_content} teaser_url={item.teaser_url} teaser_src={item.teaser_src} date={item.date} />
                ))}
            </div>
            <div id={"assessment_system"} className={"flex justify-center items-center flex-col pt-12 px-5 bg-white"}>
                <div className={"w-[100%] sm:w-[75%] xl:w-[75%] max-w-7xl mx-auto"}>
                    <a className={`block text-4xl md:text-5xl bg-clip-text text-transparent bg-[#c00000] sm:bg-gradient-to-b from-[#c00000] to-[#ffe6e6] font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.03em]' : 'tracking-[.02em]'}`}
                       href="/ai-ethics-and-safety-assessment-system"
                    >
                        {isChinese ? '人工智能伦理与安全评测体系' : 'AI Ethics and Safety Assessment System'} &#11166;
                    </a>
                </div>
            </div>
            <BrainCog_Born brainknow={true}/>
            <div className={"flex justify-center items-center flex-col pt-24 px-5 bg-white"}>
                <div className={"w-[100%] sm:w-[75%] xl:w-[75%] max-w-7xl mx-auto"}>
                    <a className={`block text-4xl md:text-5xl bg-clip-text text-transparent bg-[#c00000] sm:bg-gradient-to-b from-[#c00000] to-[#ffe6e6] font-extrabold text-center leading-relaxed md:leading-loose ${isChinese ? 'tracking-[.03em]' : 'tracking-[.02em]'}`}
                       href="/safe-ai-foundational-model"
                    >
                        {isChinese ? '安全人工智能基础模型' : 'Safe AI Foundational Model'} &#11166;
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Home