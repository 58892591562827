import React from'react';
import {useTranslation, Trans} from "react-i18next";
import {Link} from "react-router-dom";

const HomeNewsEntrance = ({teaser_title, teaser_content, teaser_url, teaser_src, date}) => {
    const {t, i18n} = useTranslation(['home', 'common'])
    return (
        <div className="gap-8 max-w-6xl mx-auto flex flex-wrap justify-content-center align-items-start">
            <img src={process.env.PUBLIC_URL + `/images${teaser_url}/${teaser_src}`} className={"max-w-[300px] w-full h-auto rounded-lg object-contain"} alt={"news-image"}/>
            <div className="sm:max-w-[400px] lg:max-w-[600px] xl:max-w-[800px]">
                <h2 className={"text-neutral-600 text-xl font-semibold mb-4"}>{teaser_title}</h2>
                <div className={"text-neutral-700 text-lg font-light font-sans"}>{teaser_content}</div>
                {/*<a href={teaser_url} className={"block mt-5 sm:mt-5 lg:mt-6 text-neutral-700 text-lg font-light uppercase"}>*/}
                <Link to={teaser_url} className={"block mt-5 sm:mt-5 lg:mt-6 text-neutral-700 text-lg font-light uppercase"}>
                    <span className="hover:underline decoration-red-600 underline-offset-8 decoration-2 transition-colors duration-1000">{t("get_started")}</span>
                    <span className="text-red-600 font-bold"> &nbsp;&#62; </span>
                </Link>
            </div>
        </div>
    )
}

export default HomeNewsEntrance;